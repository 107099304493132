import React, { useState } from 'react';
import {
	TextField,
	Button,
	Grid
} from '@material-ui/core';

import Cart from './Cart';

import api from '../api';

const renderStep = (param, currentStep) => {
	const { fields } = param;
	const dt = new Date();
	dt.setYear(dt.getFullYear() - 18);
	const maxDate = `${dt.getFullYear()}-${('0' + (dt.getMonth() + 1)).slice(-2)}-${('0' + dt.getDate()).slice(-2)}`;

	if (param?.center?.is_services_display == false) {
		if(param?.center?.covid_consent_form !== true){
			/*param.completeBooking()*/
			console.log("dsghouisdhg")
			param.handleNext("skip")
		}
	}else{
		switch (currentStep) {
			case 1:
				return <>
					<div className="form-box enter-phone-no">
						<h5>Please insert your phone number to get an activation code to {param.isManageBooking ? 'find' : 'complete'} check-in.</h5>
						<div className="form-box-inside">
							<TextField placeholder="Phone Number" maxLength="10" value={fields.mobile.value} variant="outlined" onChange={e => param.handleChange(e, 'mobile')} />
							{!fields.mobile.isValid && <small className="err">Invalid Mobile</small>}
							{param.noAppointment && <small className="err">{`There is no upcoming appointment assigned with phone number ${fields.mobile.value}`}</small>}
							<Button className="btn-blue" variant="contained" onClick={e => {e.preventDefault();param.checkAppointment();}}>
								{
									param.loading ? <><span className="spinner-grow spinner-grow-sm"></span>Loading...</> : 'Find Appointment'
								}
							</Button>
						</div>
						<p className="form-note">By providing your phone number you hereby agree to sign up for an account with our salon. You may be asked for additional information regarding your account, such as your name, e-mail address and other information related to your profile. We may also use your phone number to send you service-related notices such as special offers, feedback, notification.</p>
						<p className="form-note mrgn-top-30">*Booking Policy: If you wish to cancel your Booking, you may do so through either the Website or by contacting our salon directly. You may only cancel a Booking through the Website provided that your appointment is not due to take
							place within 24 hours of your appointment time frame.</p>
					</div>
				</>

			case 2:
				return <>
					<div className="form-box insert-activate-code">
						<h5>Please insert activation code sent to you by SMS to complete check-in.</h5>
						<div className="form-box-inside">
							<TextField maxLength="6" value={fields.activation_code.value} variant="outlined" name="activation_code" placeholder="Enter Activation Code" onChange={e => param.handleChange(e, 'activation_code')} />
							{!fields.activation_code.isValid && <small className="err">Invalid Code</small>}
							<Button className="btn-blue" variant="contained" onClick={e => {e.preventDefault(); param.activateMobile();}}>
								{
									param.loading ? <><span className="spinner-grow spinner-grow-sm"></span>Loading...</> : 'SUBMIT CODE'
								}
							</Button>
							{!!param.activationCodeCount <= 3 && <p className="form-txt-ctrl">Didn't get Code?  <a href="/#"  className="btn-link float-right color-blue" onClick={e => {e.preventDefault();param.checkAppointment();}}>Resend SMS</a></p>}
						</div>
					</div>
				</>

			case 3:
				return <>
					<div className="form-box personal-details-form">
						<h5>Customer Registration.</h5>
						<div className="form-box-inside">
							<form autoComplete="off">
								<label className="fm-ttl">Full name {!fields.name.isValid && <small className="err">This field is required</small>}</label>
								<TextField variant="outlined" name="name" value={fields.name.value || ''} placeholder="Enter your name" onChange={e => param.handleChange(e, 'name')} disabled={!!fields.customer_id} />

								{!fields.customer_id && (<>
									<label className="fm-ttl">Email <small>(optional)</small> {!fields.email.isValid && <small className="err">Please enter a valid email address</small>}</label>
									<TextField variant="outlined" name="email" value={fields.email.value || ''} placeholder="Enter your email" onChange={e => param.handleChange(e, 'email')} />
								</>)}

								<label className="fm-ttl">Phone Number {!fields.mobile.isValid && <small className="err">Please enter a valid Mobile no.</small>}</label>
								<TextField variant="outlined" name="mobile" value={fields.mobile.value || ''} placeholder="Enter your phone number" onChange={e => param.handleChange(e, 'mobile')} disabled={true} />

								{!fields.customer_id && (<>
									<label className="fm-ttl">Your Birthday <small>(optional)</small> {!fields.birthday.isValid && <small className="err">Please enter a valid Birthday</small>}</label>
									<TextField type="date" variant="outlined" name="birthday" value={fields.birthday.value || ''} placeholder="Enter your birthday" onChange={e => param.handleChange(e, 'birthday')} inputProps={{ max: maxDate }} />
								</>)}

								{!fields.customer_id && (<>
									<label className="fm-ttl">Referal Code <small>(optional)</small> {!fields.referral_code.isValid && <small className="err">Invalid referral code</small>}</label>
									<TextField variant="outlined" name="referral_code" value={fields.referral_code.value || ''} placeholder="Enter referral code" onChange={e => param.handleChange(e, 'referral_code')} />
								</>)}

								<label className="fm-ttl">Checkin Notes <small>(optional)</small></label>
								<TextField variant="outlined" multiline rows={4} name="notes" value={fields.notes.value || ''} placeholder="Enter notes for Check-in" onChange={e => param.handleChange(e, 'notes')} />

								<Button className="btn-blue" variant="contained" onClick={e => {e.preventDefault(); param.completeBooking();}}>
									{
										param.loading ? <><span className="spinner-grow spinner-grow-sm"></span>Loading...</> : 'COMPLETE CHECK-IN'
									}
								</Button>
							</form>
						</div>
					</div>
				</>
			default:
				break;
		};
	}
};

const getCustomer = (mobile, center_id, utc_offset) => {
	return api.get('/customer/mobile/:mobile', { params: { mobile, center_id, utc_offset } });
};

const getBooking = (mobile, center_id) => {
	return api.get('/booking/center/:center_id/mobile/:mobile', { params: { mobile, center_id } });
};

const addCustomer = data => {
	return api.post('/customer', data);
};

const BookingStep4 = React.memo(props => {
	const { customer = {} } = props.cart;
	const formFields = {
		customer_id: customer.id || null,
		name: {
			isValid: true,
			value: customer.fullname || ''
		},
		mobile: {
			isValid: true,
			value: customer.tel || ''
		},
		notes: {
			isValid: true,
			value: ''
		},
		activation_code: {
			isValid: true,
			value: ''
		},
		email: {
			isValid: true,
			value: customer.email || ''
		},
		birthday: {
			isValid: true,
			value: customer.birthday || ''
		},
		referral_code: {
			isValid: true,
			value: ''
		}
	};
	const [ step, setStep ] = useState(3);
	const [ token, setToken ] = useState(null);
	const [ fields, setFields ] = useState(formFields);
	const [ noAppointment, setNoAppointment ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ activationCodeCount, setActivationCodeCount ] = useState(0);

	const handleChange = (e, fieldName) => {
		const stateFields = fields;
		const maxLength = 10;
		const value = fieldName === 'mobile' ? e.target.value.replace(/[^0-9]/g, '') : e.target.value;
		if (stateFields.hasOwnProperty(fieldName)) {
			if (fieldName === 'mobile') {
				setNoAppointment(false);
				stateFields[fieldName].value = (value.length > maxLength ? value.slice(0, maxLength) : value);
			} else {
				stateFields[fieldName].value = value;
			}
		}
		setFields({...stateFields});
	};

	const validateFields = fieldsToValidate => {
		const stateFields = fields;
		let count = fieldsToValidate.length;
		for (let key in stateFields) {
			if (stateFields.hasOwnProperty(key) && fieldsToValidate.includes(key)) {
				if(key !== 'customer_id') stateFields[key].isValid = false;
				switch (key) {
					case 'name':
						if (stateFields[key].value) {
							stateFields[key].isValid = true;
							count--;
						}
					break;

					case 'mobile':
						const regex = RegExp(/^[1-9]\d{9}$/);
						stateFields[key].isValid = regex.test(stateFields[key].value);
						if (stateFields[key].isValid) {
							count--;
						}
					break;

					case 'notes':
						stateFields[key].isValid = true;
						count--;
					break;

					case 'activation_code':
						if (stateFields[key].value){
							stateFields[key].isValid = true;
							count--;
						}
					break;

					case 'email':
						stateFields[key].isValid = (!stateFields[key].value || /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(stateFields[key].value));
						if (stateFields[key].isValid) {
							count--;
						}
					break;

					case 'birthday':
						stateFields[key].isValid = true;
						count--;
					break;

					case 'referral_code':
						stateFields[key].isValid = true;
						count--;
					break;

					default:
					break;
				}
			}
		}
		setFields({...stateFields});
		return count <= 0;
	};

	const checkAppointment = () => {
		const fieldsToValidate = ['mobile'];
		if(!validateFields(fieldsToValidate)) return false;
		const d = new Date();
		const utc_offset = (d.getTimezoneOffset() * -1);
		getBooking(fields.mobile.value, props.center.id, utc_offset).then(res => {
			const { data } = res;
		}).catch(() => {
			props.handleNext(1);
		});
	};

	const activateMobile = () => {
		setLoading(true);
		return api.post('/customer/verify-otp', {
			mobile: fields.mobile.value,
			cid: props.center.id,
			token,
			otp: fields.activation_code.value
		}).then(res => {
			try{
				setLoading(false);
				const center_id = props.center.id;
				let activatedMob = JSON.parse(localStorage.getItem('activated_mob') || '{}');
				if (Array.isArray(activatedMob)) {
					activatedMob = {}
				}
				const centerActivatedMobiles = activatedMob[center_id] || [];
				if (!centerActivatedMobiles.includes(fields.mobile.value)) {
					centerActivatedMobiles.push(fields.mobile.value);
					activatedMob[center_id] = centerActivatedMobiles;
					localStorage.setItem('activated_mob', JSON.stringify(activatedMob));
				}
				afterActivation();
			} catch(e) {
				setLoading(false);
				setFields({...fields, activation_code: {...fields.activation_code, isValid: false}});
			}
		}).catch(e => {
			setLoading(false);
			setFields({...fields, activation_code: {...fields.activation_code, isValid: false}});
		});
	};

	const afterActivation = () => {
		const stateFields = fields;
		getCustomer(fields.mobile.value, props.center.id).then(res => {
			const { data } = res;
			const dt = new Date(data.birthday);
			stateFields.name.value = data.fullname;
			stateFields.email.value = data.email;
			stateFields.birthday.value = data.birthday ? `${dt.getFullYear()}-${('0' + (dt.getMonth() + 1)).slice(-2)}-${('0' + dt.getDate()).slice(-2)}` : '';
			stateFields.customer_id = data.id;
			setFields(stateFields);
			if (props.isManageBooking) {
				props.updateCustomer(data.id, data.fullname);
				props.handleNext(4);
			}
			else setStep(3);
		}).catch(() => {
			if (props.isManageBooking) {
				alert(`There is no upcoming appointment assigned with phone number ${fields.mobile.value}`);
				window.location.reload();
			}
			else setStep(3);
		});
	};

	const completeBooking = () => {
		const fieldsToValidate = ['name', 'mobile', 'notes', 'email', 'birthday', 'referral_code'];
		if(!validateFields(fieldsToValidate)) return false;
		setLoading(true);
		if (fields.customer_id) {
			props.handleBooking(fields.customer_id, fields.name.value, fields.notes.value);
		} else {
			const {
				name: { value: name },
				mobile: { value: mobile },
				email: { value: email },
				birthday: { value: birthday },
				referral_code: { value: referral_code }
			} = fields;
			const center_id = props.center.id;
			const d = new Date();
			const utc_offset = (d.getTimezoneOffset() * -1);
			addCustomer({ name, mobile, email, birthday, referral_code, center_id, utc_offset }).then(res => {
				const { status, data } = res;
				props.updateCustomer({...data});
				if (status === 200 && data.hasOwnProperty('id')) {
					props.handleBooking(data.id, name, fields.notes.value);
				}
			}).catch(() => {
				const stateFields = fields;
				stateFields.referral_code.isValid = false;
				setFields(stateFields);
				setLoading(false);
			});
		}
	};
	
    return (
		<Grid container spacing={8} className="popup-container">
            <Grid item xs={12} sm={12} md={7}>
                <div className="list-of-radio-wrapp mrgn-top-30">
					{
						renderStep({
							handleChange,
							checkAppointment,
							activateMobile,
							completeBooking,
							fields,
							noAppointment,
							loading,
							activationCodeCount,
							handleNext:props?.handleNext,
							center:props?.center,
							isManageBooking: props.isManageBooking
						}, step)
					}
                </div>
            </Grid>
			<Grid item xs={12} sm={12} md={5} className="hide-in-mob">
				<div className="mrgn-top-30">
					<Cart
						center={props.center}
						cart={props.cart}
					></Cart>
				</div>
			</Grid>
		</Grid>
    ); 
});

export default BookingStep4;