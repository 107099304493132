import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid, TextareaAutosize
} from '@material-ui/core';
import Cart from './Cart';
import api from '../api';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
const getUpcomingBooking = (cid, center_id, booking_id) => {
	const d = new Date();
	const utc_offset = (d.getTimezoneOffset() * -1);
	return api.get('/customer/:cid/center/:center_id/booking/upcoming', { params: { cid, center_id, utc_offset, booking_id } });
};

var initialNote = '';
const BookingStep5 = React.memo(({ center, cart, booking, newBooking, handleEdit, handleCancel, handleChangeBooking }) => {
	const [upcomingBooking, setUpcomingBooking] = useState([]);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [canUpdate, setCanUpdate] = useState(false);
	const [newNote, setNewNote] = useState('');
	const [isNoteEditable, setIsNoteEditable] = useState(false);

	const toggleCancel = () => {
		setCanUpdate(false);
		setShowConfirmation(!showConfirmation);
	};

	const canUpdateBooking = src => {
		setCanUpdate(false);
		const d = new Date();
		const utc_offset = (d.getTimezoneOffset() * -1);
		api.get('/booking/:id/edit/check', { params: { bid: booking.booking_id, utc_offset } }).then(res => {
			setCanUpdate(false);
			if (src === 'edit') {
				handleEdit();
			} else if (src === 'cancel') {
				toggleCancel();
			}
		}).catch(() => {
			setCanUpdate(true);
		});
	};

	useEffect(() => {
		if (cart.customer.id) {
			getUpcomingBooking(cart.customer.id, center.id, booking.booking_id).then(res => {
				const { data = [] } = res;
				const upcomingBookingNew = [];
				data.forEach(i => {
					const dt = new Date(i.time);
					let hours = dt.getHours();
					let minutes = dt.getMinutes();
					const ampm = hours >= 12 ? 'pm' : 'am';
					hours = hours % 12;
					hours = hours ? hours : 12;
					minutes = minutes < 10 ? '0' + minutes : minutes;
					const strTime = `${hours}:${minutes} ${ampm}`;
					upcomingBookingNew.push({
						...i,
						time: `${dt.getDate()} ${months[dt.getMonth()]} ${dt.getFullYear()}, ${strTime}`
					});
				});
				setNewNote(upcomingBookingNew[0].note);
				initialNote = upcomingBookingNew[0].note;
				setUpcomingBooking(upcomingBookingNew);
			}).catch(() => {
				setUpcomingBooking([]);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!booking.booking_id && upcomingBooking.length) changeBooking(upcomingBooking[0].id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upcomingBooking]);

	const changeBooking = booking_id => {
		setShowConfirmation(false);
		setCanUpdate(false);
		const selectedBooking = upcomingBooking.filter(i => i.id === booking_id)[0] || {};
		if (!selectedBooking.id) return;
		const item = selectedBooking.booking_details.map(i => ({
			id: i.service_id,
			name: i.name,
			duration: i.duration,
			price: i.price
		}));
		const detail = selectedBooking.booking_details[0];
		handleChangeBooking({
			booking_id,
			item,
			employee: {
				id: detail?.employee_id || -1,
				username: detail?.username,
				job_title: detail?.job_title,
			},
			customer_id: selectedBooking.customer_id,
			date: new Date(selectedBooking.time)
		});
	}

	const handleSetNewNote = (event) => {
		setNewNote(event.target.value);
	}

	const onEditNote = () => {
		let newEditState = !isNoteEditable;
		setIsNoteEditable(newEditState);
	}

	const updateNote = () => {
		api.post('/booking/:id/note', { note: newNote, id: booking.booking_id }).then(res => {
			let newEditState = !isNoteEditable;
			setIsNoteEditable(newEditState);
			initialNote = newNote;
		}).catch(() => {
			let newEditState = !isNoteEditable;
			setIsNoteEditable(newEditState);
			setNewNote(initialNote);
		});
	};

	return (
		<Grid container spacing={8} className="popup-container upcoming-popup-container">
			<Grid item xs={12} sm={12} md={7}>
				<div className="list-of-radio-wrapp mrgn-top-30">
					<div className="appointment-details">
						<h5>Welcome <span className="name">{cart.customer.fullname}!</span></h5>
						<h5>You successfull checked in.</h5>
						<ul className="time-list">
							{
								upcomingBooking.map(i => {
									const loopDate = new Date(i.time);
									const isActive = cart.date && (loopDate.getMonth() === cart.date.getMonth()) && (loopDate.getDate() === cart.date.getDate()) && (loopDate.getHours() === cart.date.getHours()) && (loopDate.getMinutes() === cart.date.getMinutes());
									return (
										<li key={i.id}>
											<a href="/#" className={isActive ? 'active' : ''} onClick={e => { e.preventDefault(); changeBooking(i.id) }}>
												{i.time}
											</a>
										</li>
									)
								})
							}
						</ul>
						<Button variant="contained" className="btn-blue hide-in-mob" onClick={e => { e.preventDefault(); newBooking() }}> NEW CHECK-IN </Button>
					</div>
				</div>
			</Grid>
			<Grid item xs={12} sm={12} md={5} >
				<div className="popup-sidebar-wrapp mrgn-top-30">
					<Cart
						center={center}
						cart={cart}
					></Cart>
					<div className="note-block">
						{(isNoteEditable) && <p>Notes: <img className="noteIcons" src="/images/save_ic.png" onClick={() => updateNote()} alt="" /> </p>}
						{(!isNoteEditable) && <p>Notes: <img className="noteIcons" src="/images/edit_ic.png" onClick={() => onEditNote()} alt="" /> </p>}
						<TextareaAutosize aria-label="empty textarea" placeholder="Please write down any notes"
							defaultValue={newNote} rows={3} cols={50}
							onChange={handleSetNewNote}
							onFocus={() => onEditNote()}
							onBlur={() => updateNote()}
						/>

					</div>
					{/* {
						!showConfirmation && <>
							<div className="btn-box">
								<Button variant="contained" className="btn-blue btn-reschedule" onClick={e => { e.preventDefault(); canUpdateBooking('edit') }}> Reschedule </Button>
								<Button variant="contained" className="btn-blue btn-cancel" onClick={e => { e.preventDefault(); canUpdateBooking('cancel') }}> Cancel </Button>
							</div>
						</>
					} */}

					{showConfirmation && <div className="msg-cancel">
						<h6>Are you sure that you want to cancel?</h6>
						<div className="btn-box">
							<Button variant="contained" className="btn-blue btn-cancel btn-yes" onClick={e => { e.preventDefault(); handleCancel() }}> Yes </Button>
							<Button variant="contained" className="btn-blue btn-no" onClick={e => { e.preventDefault(); toggleCancel() }}> No </Button>
						</div>
					</div>}
					{canUpdate && <small className="err">{`You can not cancel/edit the Appointment after 24-hour of appointment time. Please contact us at ${center.tel} for assistance`}</small>}
				</div>
			</Grid>
		</Grid>
	);
});

export default BookingStep5;